import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";

function App() {
  const [longUrl, setLongUrl] = useState("");
  const [customAlias, setCustomAlias] = useState("");
  const [shortUrl, setShortUrl] = useState("");
  const [customizeUrl, setCustomizeUrl] = useState(false);
  const [error, setError] = useState(null);
  const [isCooldown, setIsCooldown] = useState(false);
  const [cooldownTime, setCooldownTime] = useState(0);

  useEffect(() => {
    let timer;
    if (isCooldown) {
      timer = setInterval(() => {
        setCooldownTime((prevTime) => prevTime - 1);
        if (cooldownTime <= 0) {
          setIsCooldown(false);
          setCooldownTime(0);
          clearInterval(timer);
        }
      }, 1000);
    }
    return () => clearInterval(timer); // Clear timer on unmount or cooldown end
  }, [isCooldown, cooldownTime]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isCooldown) {
      return;
    }

    try {
      setError(null);
      setShortUrl("");
      setIsCooldown(true);
      setCooldownTime(5); // Set cooldown to 5 seconds

      const response = await axios.post(
        "https://l.longathelstan.xyz/api/shorten",
        {
          longUrl,
          customAlias: customizeUrl ? customAlias : undefined,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setShortUrl(response.data.shortUrl);
           
      setLongUrl("");
      setCustomAlias("");
    } catch (error) {
      console.error("Error shortening URL:", error);
      setError(error.response?.data?.error || "Error shortening URL. Try again.");
      setIsCooldown(false); // Reset cooldown on error
      setCooldownTime(0);
    }
  };

  return (
      <div className="home-container1">
      <form onSubmit={handleSubmit} className="home-awlong">
        {customizeUrl && (
          <div className="home-container2">
            <span className="home-text1">l.longathelstan.xyz/</span>
            <input
              type="text"
              value={customAlias}
              onChange={(e) => setCustomAlias(e.target.value)}
              placeholder="CUSTOM URL"
              className="home-textinput1 input"
            />
          </div>
        )}

        <button
          type="button"
          className="home-button1 button"
          onClick={() => setCustomizeUrl(!customizeUrl)}
        >
          <svg
            height="24"
            width="24"
            viewBox="0 0 24 24"
            className="home-icon1"
          >
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M18 3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3H6a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3a3 3 0 0 0-3 3a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3a3 3 0 0 0-3-3"
            ></path>
          </svg>
          <span className="home-text2">
            <span>CUSTOMISE LINK</span>
            <br></br>
          </span>
        </button>
        <div className="home-container3">
          <input
            type="text"
            value={longUrl}
            onChange={(e) => setLongUrl(e.target.value)}
            placeholder="PASTE URL, SHORTEN & SHARE"
            required
            className="home-textinput2 input"
          />
          <button
            type="submit"
            className={`home-button2 button ${isCooldown ? "cooldown" : ""}`}
            disabled={isCooldown}
            style={
              isCooldown
                ? { opacity: 0.7, cursor: "not-allowed" }
                : {}
            }
          >
            <span>
              {isCooldown ? `SHORTEN (${cooldownTime})` : "SHORTEN"}
              <br />
            </span>
          </button>
        </div>
        <div className="home-container4">
          <h1 className="home-text8">Awlong-link shortener</h1>
          <svg
            height="24"
            width="24"
            viewBox="0 0 24 24"
            className="home-icon3"
          >
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M14.5 10c-.83 0-1.5-.67-1.5-1.5v-5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5m6 0H19V8.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5s-.67 1.5-1.5 1.5m-11 4c.83 0 1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5S8 21.33 8 20.5v-5c0-.83.67-1.5 1.5-1.5m-6 0H5v1.5c0 .83-.67 1.5-1.5 1.5S2 16.33 2 15.5S2.67 14 3.5 14m10.5.5c0-.83.67-1.5 1.5-1.5h5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-5c-.83 0-1.5-.67-1.5-1.5m1.5 4.5H14v1.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5M10 9.5C10 8.67 9.33 8 8.5 8h-5C2.67 8 2 8.67 2 9.5S2.67 11 3.5 11h5c.83 0 1.5-.67 1.5-1.5M8.5 5H10V3.5C10 2.67 9.33 2 8.5 2S7 2.67 7 3.5S7.67 5 8.5 5"
            ></path>
          </svg>
        </div>
        {shortUrl && (
          <div className="result">
            <p>Link đã rút gọn:</p>
            <a href={shortUrl} target="_blank" rel="noopener noreferrer" className="shortUrl">
              {shortUrl}
            </a>
          </div>
        )}
        {error && <p className="error-message">{error}</p>}
      </form>
    </div>
  );
}

export default App;
